import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CrossPlatformModule} from '../../../../../framework/src/cross-platform/cross-platform.module';
import {FacadeFormsModule} from '../../../../../framework/src/desktop/app/forms/facade-forms.module';
import {AckDesktopHeaderComponent} from './header/ack-desktop-header.component';
import {GlobalSharedUIModule} from '../../../../../framework/src/desktop/app/shared/ui/global-shared-ui.module';
import {
    InjectComponentService
} from '../../../../../framework/src/cross-platform/inject-component/inject-component.service';
import {AckAppFooterComponent} from './footer/ack-app-footer.component';
import {AckNewsletterSignUpComponent} from './footer/ack-newsletter-sign-up.component';
import {CrossPlatformSharedModule} from '../../cross-platform/cross-platform-shared.module';
import {AppModule, translateConfig} from '../../../../../framework/src/desktop/app/app.module';
import {CrossPlatformImplementationAppModule} from '../../cross-platform/cross-platform-implementation-app.module';
import {AckAppComponent} from './ack-app.component';
import {
    ImplementationDataService
} from '../../../../../framework/src/cross-platform/implementation-config/implementation-data.service';
import {FacadePlatform} from '../../../../../framework/src/cross-platform/shared/facade-platform';


const injectableComponents = [
    AckAppFooterComponent,
    AckDesktopHeaderComponent
];

const injectableComponentsToRegister = [
    {className: 'AppFooterComponent', componentClass: AckAppFooterComponent},
    {className: 'DesktopHeaderComponent', componentClass: AckDesktopHeaderComponent}
];


@NgModule({
    imports: [
        AppModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(translateConfig),
        CrossPlatformModule,
        CrossPlatformSharedModule.forRoot(),
        // Overriding
        CrossPlatformImplementationAppModule.forRoot(),
        FacadeFormsModule,
        RouterModule,
        GlobalSharedUIModule,
        // Comment out if you want to remove mock functionality
        //AckMockModule,
        //MockModule
    ],
    declarations: [
        AckDesktopHeaderComponent,
        AckNewsletterSignUpComponent,
        AckAppComponent,
        // Injectable components
        ...injectableComponents
    ],
    exports: [
        AckDesktopHeaderComponent
    ],
    bootstrap: [AckAppComponent]
})
export class DesktopImplementationAppModule {

    constructor(injectComponentService:InjectComponentService,
                private implementationDataService:ImplementationDataService,
                private facadePlatform:FacadePlatform) {
        injectComponentService.registerOverrides(injectableComponentsToRegister);
        this.registerRunTimeModules();
    }

    async registerRunTimeModules() {
        this.implementationDataService.registerGetCustomModulePathFromName(async (moduleName) => {
            let module;
            switch (moduleName) {
                case 'donation':
                    module = await import('../../../../../framework/src/desktop/app/wp-content/components/wp-donation/wp-donation.module');
                    module = module['WpDonationModule'];
                    break;
                case 'facade-swiper-desktop':
                    module = await import('../../../../../framework/src/desktop/app/shared/ui/facade-swiper/facade-swiper-lazy.module');
                    module = module['FacadeSwiperLazyModule'];
                    break;
                case 'cellular-contracts':
                    module = await import('../../../../../framework/src/desktop/app/wp-content/components/wp-cellular-contracts/wp-cellular-contracts.module');
                    module = module['WpCellularContractsModule'];
                    break;
                case 'tiendeo-catalogues':
                    module = await import('../../../../../framework/src/desktop/app/wp-content/components/wp-tiendeo-catalogues/wp-tiendeo-catalogues.module');
                    module = module['WpTiendeoCataloguesModule'];
                    break;
            }
            return module;
        });
    }
}
