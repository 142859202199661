import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DesktopHeaderComponent} from '../../../../../../framework/src/desktop/app/header/desktop-header.component';
import {AuthType} from '../../../../../../framework/src/cross-platform/auth/components/auth.types';
import {
    FMDesktopTopMenuItemGroup,
    FMMenuItemType
} from '../../../../../../framework/src/cross-platform/external-data/firebase/firebase.types';
import {AckBuiltInPageMapping} from '../../../cross-platform/ack-custom-page-mapping';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    LogoutPromptService
} from '../../../../../../framework/src/desktop/app/auth/logout-prompt/logout-prompt.service';
import {FakeLinkEvent} from '../../../../../../framework/src/cross-platform/shared/ui/fake-link.directive';
import {environment} from '../../../../../../framework/src/cross-platform/implementation/environment';


@Component({
    selector       : 'desktop-header',
    templateUrl    : './ack-desktop-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AckDesktopHeaderComponent extends DesktopHeaderComponent {

    isSearchResultsPage   = false;
    AuthType              = AuthType;
    AckBuiltInPageMapping = AckBuiltInPageMapping;
    authMenuMainItem:FMDesktopTopMenuItemGroup;

    protected storeCreditEnabled   = environment.config.api.storeCreditEnabled;
    protected sisterSiteBarEnabled = this.implementationDataService.getSisterSiteSettings()?.length > 0;

    constructor(injector:Injector, public router:Router, public ngbModal:NgbModal, public logoutPromptService:LogoutPromptService) {
        super(injector);
        this.authMenuMainItem = {
            type    : FMMenuItemType.customButton,
            locale  : 'account.menu.accountDetails',
            link    : this.urlService.buildUrlForAccountRoot(),
            dropDown: {
                columns: []
            }
        };
    }

    async init() {
        super.init();

        this.addSub = this.globalSelectors.cartQuantity$.subscribe(
            (cartTotal:number) => {
                this.cartTotal = cartTotal;
                this.quickCartUpdateAvailable = true;
                this.changeDetectorRef.detectChanges();
            }
        );

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event:NavigationEnd) => {
                this.isSearchResultsPage = this.urlService.isSearchResultsUrl(event.url);
                //console.log('isSearchResultsPage: ', this.isSearchResultsPage);
                this.changeDetectorRef.detectChanges();
            });
    }

    handleLogout($event:FakeLinkEvent) {
        this.logoutPromptService.initiateLogout($event);
    }

}
