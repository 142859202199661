import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
    ImplementationDataService,
    ProductFilterSettings,
} from '../../../../../framework/src/cross-platform/implementation-config/implementation-data.service';
import {
    NoContentLink
} from '../../../../../framework/src/cross-platform/no-content-page/abstract-no-content-include.component';
import {SEODefaults} from '../../../../../framework/src/cross-platform/seo/seo-service';
import {
    ProductGallerySettings,
    ProductModuleConfig,
    ProductModuleType
} from '../../../../../framework/src/cross-platform/product-page/product.types';
import {StockOnHandType} from '../../../../../framework/src/cross-platform/stock-on-hand/stock-on-hand.types';
import {
    RegistrationFormSettings
} from '../../../../../framework/src/cross-platform/auth/components/abstract-auth-register.component';
import {AckCMSPageLinks, AckExternalSites} from '../ack-custom-page-mapping';
import {Rectangle} from '../../../../../framework/src/cross-platform/shared/ui/facade-swiper/facade-swiper.types';
import {CountryPrefixCode, TelInputUtils} from '../../../../../framework/src/cross-platform/forms/tel-input.utils';
import {SwiperOptions} from 'swiper';
import {
    AplusPromotionSettings,
    BrowserUpgradeOptions,
    DeliverySettings,
    DeliveryType,
    ImplementationLinks,
    PaymentLogoType,
    PlusMorePostRegisterPromotion,
    RecaptchaSectionType,
    ScrollSettings,
    SisterSiteSettings
} from '../../../../../framework/src/cross-platform/implementation-config/implementation-data.types';
import {CookieService} from 'ngx-cookie-service';
import {GenericSEOData} from '../../../../../framework/src/cross-platform/seo/seo-types';
import {CMSPageEnum} from '../../../../../framework/src/cross-platform/router/page-type.enum';
import {environment} from '../../../../../framework/src/cross-platform/implementation/environment';
import {MagentoShippingMethods} from '../../../../../framework/src/cross-platform/external-data/enum/magento-constants';
import {StorageService} from '../../../../../framework/src/cross-platform/shared/ui/storage/storage.service';
import {StaticConfig} from '../../../../../framework/src/cross-platform/shared/static-config';

@Injectable()
export class AckImplementationDataService extends ImplementationDataService {


    private cookieService:CookieService;
    private homeDeliveryOverrideEnabled?:boolean;
    private plusMoreOverrideEnabled?:boolean;
    private einsteinLaybyOverrideEnabled?:boolean;
    private einsteinAccountOverrideEnabled?:boolean;

    constructor(injector:Injector) {
        super(injector);
        this.cookieService                  = injector.get(CookieService);
        this.homeDeliveryOverrideEnabled    = injector.get(StorageService).getItemBoolOrNull(StaticConfig.ENABLE_HOME_DELIVERY);
        this.plusMoreOverrideEnabled        = injector.get(StorageService).getItemBoolOrNull(StaticConfig.ENABLE_PLUS_MORE);
        this.einsteinLaybyOverrideEnabled   = injector.get(StorageService).getItemBoolOrNull(StaticConfig.ENABLE_EINSTEIN_LAYBY);
        this.einsteinAccountOverrideEnabled = injector.get(StorageService).getItemBoolOrNull(StaticConfig.ENABLE_EINSTEIN_ACCOUNT);
    }

    getSEOBase(env:string, versionNumber:string):GenericSEOData {
        return environment.config.api.seoBase;
    }

    getSEODefaults():SEODefaults {
        return new SEODefaults(
            this.getSEOBase(null, null).title,
            this.getSEOBase(null, null).description
        );
    }

    getNoContentLinks():Observable<NoContentLink[]> {
        return of([
            {href: AckCMSPageLinks.corporateInformationCareers, localeKey: 'menu.more.careers'},
            {href: this.urlService.buildUrlForContact(), localeKey: 'menu.contactUs'},
            {href: this.urlService.buildUrlForStoreFinder(), localeKey: 'menu.findAStore'},
            {href: this.urlService.buildUrlForHome(), localeKey: 'menu.home'},
        ]);
    }

    getImplementationLinks():ImplementationLinks {
        return {
            pepkorRetailSegments  : AckExternalSites.pepkorRetailSegments,
            pepkorPrivacyStatement: AckExternalSites.pepkorPrivacyStatement,
            termsGeneral          : AckCMSPageLinks.termsGeneral,
            termsCookiePolicy     : AckCMSPageLinks.termsCookiePolicy,
            shippingInfo          : AckCMSPageLinks.termsClickAndCollect,
            returnsFAQ            : AckCMSPageLinks.returnsFAQ
        };
    }

    getRegistrationFormSettings():RegistrationFormSettings {
        return {
            checks: [
                // UNDONE: removed for plus more skin
                // {localeKey: 'authReg.termsCheck', required: true, link: AckCMSPageLinks.termsGeneral, linkLocaleKey: 'authReg.termsCheckLink'},
                {localeKey: 'authReg.dealsCheck', required: false, isNewsletterSubscription: true, link: AckCMSPageLinks.termsGeneral, linkLocaleKey: 'authReg.termsCheckLink', asToggle: true}
            ]
        };
    }

    roundOverIonicButtons() {
        return true;
    }

    getCountryPrefixCodes():CountryPrefixCode[] {
        return [
            TelInputUtils.regions.southAfrica,
            TelInputUtils.regions.botswana,
            TelInputUtils.regions.lesotho,
            TelInputUtils.regions.malawi,
            TelInputUtils.regions.mozambique,
            TelInputUtils.regions.namibia,
            TelInputUtils.regions.swaziland,
            TelInputUtils.regions.zambia,
            TelInputUtils.regions.zimbabwe
        ];
    }

    getDefaultSwiperEffect():{ desktop:string, mobile:string } {
        return {desktop: 'slide', mobile: 'slide'};
    }


    getStaticPageSwiperRatio():Rectangle {
        if (this.facadePlatform.isDesktop) {
            return {width: 1920, height: 737};
        }
        else {
            return {width: 350, height: 500};
        }
    }

    getShowProductImageThumbnailsOnMobile() {
        return false;
    }

    getProductImageRatio():number {
        return 2 / 3;
    }

    getEnableAccountEdit() {
        return false;
    }

    getPromptOnAbandonCart() {
        return true;
    }

    getEnableAccountApplicationStatus() {
        // TODO ACK Multiple Product Connections
        return true;
        //return false;
    }

    getShowAddThisShareButtons():boolean {
        return false;
    }

    getEnableMobileSearchHeader() {
        return false;
    }

    getShowNewProductListCategoryNavigation():boolean {
        return true;
    }

    getPageHeaderOffset():number {
        if (this.facadePlatform.isDesktop) {
            return 70;
        }
        else {
            return 0;
        }
    }

    getCarouselViewLink():boolean {
        return true;
    }

    getProductCarouselSwiperConfig():SwiperOptions {
        if (this.facadePlatform.isDesktop) {
            return {
                breakpoints  : {
                    1500: {
                        slidesPerView: 6,
                        spaceBetween : 24,
                    },
                },
                spaceBetween : 24,
                slidesPerView: 6
            };
        }
        else {
            return {
                breakpoints: {
                    1  : {
                        slidesPerView: 1,
                        spaceBetween : 1,
                    },
                    300: {
                        slidesPerView: 2,
                        spaceBetween : 10,
                    },
                    360: {
                        slidesPerView: 2,
                        spaceBetween : 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween : 15,
                    },
                },
                // UNDONE:   Object literal may only specify known properties, and 'breakpointsInverse' does not exist in type 'SwiperOptions'.
                // Using the inverse seems to fix a bug on mobile
                // breakpointsInverse: true
            };
        }
    }

    getProductGallerySettings():ProductGallerySettings {
        return (this.facadePlatform.isDesktop) ? {
            verticalAlign: true,
            slider       : {
                blockWidth : 150,
                blockHeight: 225,
                gap        : 8
            }
        } : {
            verticalAlign: false
        };
    }

    getProductCustomActions() {
        return [
            {
                label: 'Open an Ackermans Account',
                url  : 'https://apply.ackermans.co.za/?utm_source=ackermans.co.za&utm_medium=product-page-button&utm_campaign=cross-domain-tracking',
                type : 'button'
            },
            {
                label: 'How to get this on layby',
                url  : '/page/financial-solutions/lay-by',
                type : 'button'
            },
            // {
            //     label: 'Find a store near you',
            //     url  : this.urlService.buildUrlForStoreFinder()
            // },

        ];
    }

    getShowProductsTooltip():boolean {
        return true;
    }

    getShowProductCategoryLabels():boolean {
        return true;
    }

    getAccordionConfig():{ scrollingOffset:number } {
        if (this.facadePlatform.isDesktop) {
            return {
                scrollingOffset: -70
            };
        }
        else {
            return {
                scrollingOffset: 0
            };
        }
    }

    getBackToTopConfig():{ duration:number } {
        return {duration: 300};
    }

    getStockOnHand():StockOnHandType {
        return StockOnHandType.ACK;
    }

    getAutoCompleteMaxItems():number {
        return 3;
    }

    /* NEW ECOMMERCE MODULES */

    /* see title translations in .properties file */
    getProductModules():ProductModuleConfig[] {
        let modules:ProductModuleConfig[] = [];

        if (this.getEcomEnabled()) {
            modules = modules.concat([
                {
                    id         : 'product-details',
                    title      : 'product.module.details',
                    icon       : 'icon-list',
                    type       : ProductModuleType.ATTRIBUTES,
                    collapsable: true,
                    defaultOpen: false
                },
                {
                    id         : 'shipping',
                    title      : 'product.module.shipping',
                    icon       : 'icon-shipping',
                    type       : ProductModuleType.INFO,
                    collapsable: true,
                    defaultOpen: false,
                    content    : {
                        descriptionLocaleKey: 'product.module.shippingDescription',
                        linkLocaleKey       : 'product.module.shippingLink',
                        modal               : {
                            headingLocaleKey   : 'product.module.shippingLink',
                            bodyLocaleKey      : 'product.module.shippingDetail',
                            bodyLocaleVariables: {termsPage: this.getImplementationLinks().shippingInfo}
                        }
                    }
                },
                {
                    id         : 'returns',
                    title      : 'product.module.returns',
                    icon       : 'icon-return',
                    type       : ProductModuleType.INFO,
                    collapsable: true,
                    defaultOpen: false,
                    content    : {
                        descriptionLocaleKey: 'product.module.returnsDescription',
                        linkLocaleKey       : 'product.module.returnsLink',
                        link                : {
                            path  : AckCMSPageLinks.returnsFAQ,
                            target: '_blank'
                        }
                    }
                },
                {
                    id         : 'product-guides',
                    title      : 'product.module.guides',
                    icon       : 'icon-tag',
                    type       : ProductModuleType.WASHCARE_GUIDE,
                    collapsable: false,
                    defaultOpen: false,
                    content    : {
                        modal: {
                            headingLocaleKey: 'product.module.shippingLink',
                            cmsContentSlug  : CMSPageEnum.WASHCARE_GUIDE
                        }
                    }
                }
            ]);
        }


        if (this.getShowAddThisShareButtons()) {
            modules.push({
                id         : 'product-share',
                title      : 'product.module.share',
                icon       : 'icon-share',
                type       : ProductModuleType.SHARE,
                collapsable: true,
                defaultOpen: false
            });
        }

        return modules;
    }

    getPromotionModule():ProductModuleConfig {
        if (this.getEcomEnabled()) {
            return {
                id         : 'product-promotion',
                title      : 'product.module.promotions',
                type       : ProductModuleType.PROMOTIONS,
                collapsable: true,
                defaultOpen: false
            };
        }
        return null;
    }

    getEcommerceEnabled():boolean {
        return true;
    }

    getProductFilterSettings():ProductFilterSettings {
        return {
            showFirstOnly               : true,
            showCategoryChildrenOnly    : true,
            showSortOnFilter            : true,
            showOnSaleFilter            : true,
            showInStockFilter           : false,
            funnelFilterAttributes      : true,
            usePriceSlider              : true,
            filterCollapsable           : true,
            shouldFilterSizeInStock     : false,
            productCategoryFilterEnabled: false,
            productCategoryNavEnabled   : this.facadePlatform.isDesktop,
            productFilterCategoryLinks  : {
                enabled    : false,
                parentLevel: 3
            },
            attributesFilter            : {
                exclude: ['pim_product_category']
            },
            filterSortOrder             : {
                'onsale'              : 1,
                'sort'                : 2,
                'category-nav'        : 3,
                'category-links'      : 4,
                'pim_product_category': 5,
                'priceRange'          : 6,
                'primarycolour'       : 7,
                'productsize'         : 8,
                'brand'               : 9,
                'gender'              : 10,
                'instock'             : 11,
            },
            replace                     : {
                primarycolour: 'Colour'
            }
        };
    }

    getBrowserUpgradeCheckEnabled():BrowserUpgradeOptions {
        if (this.facadePlatform.isDesktop) {
            return null;
        }
        else {
            const options:BrowserUpgradeOptions = {
                required: {
                    e      : 85, // explorer / edge
                    f      : 84, // firefox
                    o      : 73, // opera
                    s      : 12, // safari
                    c      : 88,  // chrome
                    samsung: 11 //samsung internet
                },
                // reminder: after how many hours should the message reappear
                reminder: 720, // in hours - 30 days
                // reminderClosed: if the user explicitly closes message it reappears after x hours
                reminderClosed: 720, // in hours - 30 days
                api           : 2021.05,
                insecure      : true,
                test          : false,
                style         : 'bottom'
            };
            return options;
        }
    }

    setEcomEnabled(value:boolean) {
        this.cookieService.set('EcomEnabled', `${value}`);
    }

    getEcomEnabled():boolean {
        return true;
        /*const eComEnabled:string = this.cookieService.get('EcomEnabled');
        return (eComEnabled === 'true');*/
    }

    getCustomerProfileEnabled():boolean {
        return true;
    }

    getCatalogModalEnabled():boolean {
        return true;
    }

    getShowWasNowPrice():boolean {
        return true;
    }

    getShowAPlus():boolean {
        return true;
    }

    getShowShopNowButtonOnProductListPage():boolean {
        return false;
    }

    getUseStockCheckOnProductListPage():boolean {
        return true;
    }

    getHasPimSupport() {
        return true;
    }

    getUseSOHDataForStoreFinder() {
        return true;
    }

    getColorAttrName() {
        return 'colour';
    }

    getSizeAttrName() {
        return 'productsize';
    }

    getShowCmsMenuContent():boolean {
        return true;
    }

    getBarcodeSeach():boolean {
        return true;
    }

    getScrollSettings():ScrollSettings {
        if (this.facadePlatform.isDesktop) {
            return {
                header        : {
                    fixed              : true,
                    scrollingOffset    : 0,
                    hideOnScrollDown   : true,
                    conditionalCssClass: 'sister-site-bar',
                    collapseHeight     : 122,
                    unCollapseAtBottom : true
                },
                productFilters: {
                    fixed         : true,
                    adjustToHeader: true,
                    headerOffset  : this.getSisterSiteSettings()?.length > 0 ? 173 : 125,
                    footerOffset  : 40
                }
            };
        }
        else {
            return {
                header: {
                    hideOnScrollDown   : true,
                    collapseHeight     : 42,
                    conditionalCssClass: 'sister-site-bar',
                    unCollapseAtBottom : true
                }
            };
        }
    }

    getNoIndexMinProductCount():number | undefined {
        return 3;
    }

    getShowBillingAddressInCheckout() {
        return true;
    }

    getAiRecommendationsToShow():{ similar:boolean, outfit:boolean, color:boolean } {
        return {similar: true, outfit: true, color: true};
    }

    getContentItemsCarouselSwiperConfig(style:'style1' | 'style2' | 'style3' | 'style4'):SwiperOptions {
        if (this.facadePlatform.isDesktop) {
            if (style === 'style1' || style === 'style2') {
                return {
                    slidesPerView: 3,
                    spaceBetween : 16,
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: false,
                        enabled  : false
                    },
                };
            }
            else if (style === 'style3' || style === 'style4') {
                return {
                    slidesPerView: 6,
                    spaceBetween : 48,
                    navigation   : {
                        enabled: true,
                    },
                    pagination   : {
                        clickable: false,
                        enabled  : false
                    },
                };
            }
            else if (style === 'icon-banners') {
                return {
                    slidesPerView: 3,
                    spaceBetween : 16,
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: false,
                        enabled  : false
                    },
                    autoplay     : false,
                    loop         : false
                };
            }
        }
        else {
            if (style === 'style1' || style === 'style2') {
                return {
                    slidesPerView: 1,
                    spaceBetween : 16,
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : false
                    },
                };
            }
            else if (style === 'style3' || style === 'style4') {
                return {
                    slidesPerView: 2,
                    spaceBetween : 48,
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                };
            }
            else if (style === 'icon-banners') {
                return {
                    slidesPerView: 1,
                    spaceBetween : 16,
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                    autoplay     : true,
                    loop         : true
                };
            }
            return {
                breakpoints: {
                    360: {
                        slidesPerView: 3,
                        spaceBetween : 10,
                        pagination   : {
                            clickable: true,
                            enabled  : false
                        },
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween : 15,
                        pagination   : {
                            clickable: true,
                            enabled  : false
                        },
                    },
                },
                pagination : {
                    clickable: true,
                    enabled  : false
                },
                navigation : {
                    enabled: false,
                }
            };
        }
    }

    recaptchaEnabled() {
        return true;
    }

    recaptchaNoticeEnabled(section:RecaptchaSectionType) {
        return this.recaptchaEnabled() && [RecaptchaSectionType.AUTH_REGISTER, RecaptchaSectionType.CONTACT_US].indexOf(section) >= 0;
    }

    getDeliverySettings():DeliverySettings {
        return {
            homeDelivery              : this.homeDeliveryOverrideEnabled != null ? this.homeDeliveryOverrideEnabled : true,
            deliveryPageStaticInfoSlug: 'delivery-areas',
            forceGoogleValidation     : true,
            forceLeadingStreetNumber  : true,
            options                   : [
                {
                    type        : DeliveryType.COLLECT,
                    heading     : 'homeDelivery.options.clickAndCollect.heading',
                    detail      : 'homeDelivery.options.clickAndCollect.detail',
                    deliveryTime: 'homeDelivery.options.clickAndCollect.deliveryTime',
                    methods     : [{
                        title   : 'homeDelivery.options.method.clickcollect',
                        code    : MagentoShippingMethods.clickAndCollect,
                        currency: 'R'
                    }]
                },
                {
                    type        : DeliveryType.DELIVERY,
                    heading     : 'homeDelivery.options.delivery.heading',
                    detail      : 'homeDelivery.options.delivery.detail',
                    deliveryTime: 'homeDelivery.options.delivery.deliveryTime',
                    methods     : [{
                        title   : 'homeDelivery.options.method.economy',
                        code    : MagentoShippingMethods.economy,
                        currency: 'R'
                    }]
                }
            ]
        };
    }

    paymentPageContent():string {
        return 'payment-page-content';
    }

    getPushNotificationConfig() {
        return {
            //enabled: true,
            enabled: true,
            delay  : 1000 * 60
            //delay: 0
        };
    }

    getSisterSiteSettings():SisterSiteSettings[] {
        return [{
            imageUrl: 'assets/images/sister-sites/ackermans.eab714cce258de1f6c71ede4a5c77873.svg',
            link    : environment.config.sisterSites?.ackermans,
            alt     : 'Ackermans',
            active  : false
        }
        // , {
        //     imageUrl: 'assets/images/sister-sites/connect.b550e2621f0cd767720136de26e5c391.svg',
        //     link    : environment.config.sisterSites?.connect,
        //     alt     : 'Ackermans Connect',
        //     active  : false
        // }
        // , {
        //     imageUrl: 'assets/images/sister-sites/cube.b29b0c5f0cf4dd7a21ac1550b1a6991e.svg',
        //     link    : environment.config.sisterSites?.cube,
        //     alt     : 'CUBE',
        //     active  : false
        // }
        ];
    }

    getPaymentLogos():PaymentLogoType[] {
        return [
            {
                type : 'cards',
                items: [{
                    label   : 'VISA',
                    imageUrl: 'assets/images/payment-logos/visa.303255cc0f3f45b3af541b540192dd14.svg',
                }, {
                    label   : 'Mastercard',
                    imageUrl: 'assets/images/payment-logos/mastercard.db525a7889d10138cc54d7323031240b.svg',
                }
                    // , {
                    //     label   : 'Capitec Pay',
                    //     imageUrl: 'assets/images/payment-logos/capitec.e7a7f869916259688497370ff8660fc4.svg',
                    // }, {
                    //     label   : 'Payflex',
                    //     imageUrl: 'assets/images/payment-logos/pay-flex.5ece9606df5508313ab02065017d7793.svg',
                    // }
                ]
            },
            {
                type : 'accounts',
                items: [{
                    label   : 'Ackermans',
                    imageUrl: 'assets/images/payment-logos/ackermans.ef80428453c3dea01577e23324dd981d.svg',
                }, {
                    label   : 'PEP',
                    imageUrl: 'assets/images/payment-logos/pep.1112982b8d63a0e74b940be3bd9d9b05.png',
                }, {
                    label   : 'Dunns',
                    imageUrl: 'assets/images/payment-logos/dunns.d2edff171d50ff16ae36a27c4d506a81.svg',
                }, {
                    label   : 'TekkieTown',
                    imageUrl: 'assets/images/payment-logos/tekkie-town.fb94239c2a5599753a14d3fbaa3c80a5.png',
                }, {
                    label   : 'Refinery',
                    imageUrl: 'assets/images/payment-logos/refinery.5033898d3101a919e3cdd9888cea72fb.svg',
                }]
            }
        ];
    }

    sohHideOutOfStockStores():boolean {
        return true;
    }

    stickyBuyButton():boolean {
        return this.facadePlatform.isDesktop ? false : true;
    }

    getAplusPromotionSettings():AplusPromotionSettings {

        return {
            enabled        : true,
            link           : AckExternalSites.ackAccountApplication,
            imageUrl       : 'assets/images/payment-logos/aplus-large.67a4032c076e0a420413bb294380b81f.svg',
            interestRate   : 21 / 100,
            repaymentTerm  : 12,
            monthServiceFee: 21,
            paymentLogos   : [{
                label   : 'PEP',
                imageUrl: 'assets/images/payment-logos/pep-colour.240315f7506a89d582099cc001d78635.png',
            }, {
                label   : 'Dunns',
                imageUrl: 'assets/images/payment-logos/dunns-colour.c6013f9778e97986bc5e646031940f0b.svg',
            }, {
                label   : 'TekkieTown',
                imageUrl: 'assets/images/payment-logos/tekkie-town-colour.b05f0e7e5336a4b2d618b5ec288dd83b.png',
            }, {
                label   : 'Refinery',
                imageUrl: 'assets/images/payment-logos/refinery-colour.cb957a08a57d32e0491f4665ef753de1.svg',
            }]
        };
    }

    getQuickCartEnabled():boolean {
        return true;
    }

    getPlusMorePostRegisterPromotion():PlusMorePostRegisterPromotion {
        return {
            enabled       : true,
            querySelector : 'promotion',
            queryValue    : 'plusmore',
            cssClass      : 'plusmore-modal',
            cmsContentSlug: ['plusmore', 'account-prompt'],
            ctaButtons    : [{
                locale: 'account.plusMore.continueShopping',
                type  : 'primary',
                action: 'cancel'
            }, {
                locale: 'account.plusMore.viewRewards',
                type  : 'light',
                action: 'view'
            }]
        };
    }

    getPlusMoreEnabled() {
        return true;
    }

    getPlusMoreEnabledOnForms() {
        return this.getPlusMoreEnabled() && (this.plusMoreOverrideEnabled != null ? this.plusMoreOverrideEnabled : false);
    }

    getEinsteinLaybyEnabled():boolean {
        return this.einsteinLaybyOverrideEnabled != null ? this.einsteinLaybyOverrideEnabled : true;
    }

    getEinsteinAccountEnabled():boolean {
        return this.einsteinAccountOverrideEnabled != null ? this.einsteinAccountOverrideEnabled : true;
    }
}
