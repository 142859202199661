<ng-container *ngIf="viewProps$ | async as viewProps">

    <div *ngIf="viewProps.submitted === true" class="response-msg padding d-flex flex-column justify-content-center">
        <ng-container *ngIf="!viewProps.duplicateEmail">
            <h3 class="list-header">{{'newsletter.sentTitle' | translate}}</h3>
            <p class="newsletter-info">{{'newsletter.sentBody' | translate}}</p>
        </ng-container>
        <ng-container *ngIf="viewProps.duplicateEmail">
            <h3 class="list-header">{{'newsletter.sentTitleDup' | translate}}</h3>
            <p class="newsletter-info">{{'newsletter.sentErrorDup' | translate}}</p>
        </ng-container>
    </div>

    <form *ngIf="!viewProps.submitted" (ngSubmit)="handleSubmit()" [formGroup]="newsletterForm" novalidate class="d-flex flex-row justify-content-center">
        <!--<span class="list-header">{{'newsletter.header' | translate}}</span>-->
        <div class="d-flex newsletter-signup-form-inner">
            <div class="newsletter-info flex-nowrap">
                <span class="newsletter-info__heading">{{'newsletter.info' | translate}}</span>
                <span class="newsletter-info__detail">{{'newsletter.infoReason' | translate}}</span>
            </div>

            <div class="newsletter-form-fields">
                <div class="input-group">
                    <d-form-input
                        [placeHolder]="'newsletter.email' | translate"
                        [formControlName]="'emailControl'"
                        id="emailField"
                    ></d-form-input>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-primary subscribe-but btn-lg" [attr.disabled]="formIsDisabled ? 'disabled' : null">
                            <span *ngIf="!formIsDisabled">{{'newsletter.subscribe'  | translate}}</span>
                            <span *ngIf="formIsDisabled"><spinner></spinner></span>
                        </button>
                    </div>
                </div>
                <error-message *ngIf="(state$ | async)?.isError" [errorMsgHtml]="'error.defaultMessage' | translate"></error-message>
            </div>
        </div>
    </form>
</ng-container>
