import {gql} from 'apollo-angular';

export const LINK_CUSTOMER_ID_NUMBER_EINSTEIN = gql`
    mutation linkCustomerIdNumber($customerIdNumber: String!) {
        linkCustomerIdNumber(customerIdNumber: $customerIdNumber) {
            ResponseCode
            ResponseDescription
        }
    }
`;
