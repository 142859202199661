import {gql} from 'apollo-angular';

export const FETCH_CUSTOMER_INFO_EINSTEIN_CACHE = false;

export const FETCH_CUSTOMER_INFO_EINSTEIN = gql`
    query fetchCustomerInfo {
        fetchCustomerInfo {
            CustomerDetails {
                AccountList {
                    Account {
                        AccountBalance {
                            AccountNumber
                            AvailableCTAmount
                            BalanceAmount
                            CreditLimitAmount
                            FirstName
                            Initials
                            LastName
                            MinimumPaymentDueAmount
                            PastDueAmount
                            PaymentDueDate
                            RequirePaymentAmount
                            Title
                            TotalPaymentDueAmount
                        }
                        AccountNumber
                        AccountStatus
                        CardNumber
                        CardStatus
                        ConceptCode
                        CountryCode
                        CurrencySymbol
                        ProductNumber
                        RetailerCode
                        Statements {
                            Statement {
                                AvailableCredit
                                CDStatusNumber
                                CloseBalanceAmount
                                CloseBalanceDate
                                CreditLimitAmount
                                InstalmentAmount
                                OpenBalanceAmount
                                OpenBalanceDate
                                OverdueAmount
                                PaymentDueAmount
                                PaymentDueDate
                                StatementDate
                                StatementNumber
                            }
                        }
                    }
                }
                Address1
                Address2
                AltContactNo
                AplusApplicationInformation {
                    AplusApplicationDescription
                    AplusApplicationStatus
                }
                BirthCountryCode
                CellphoneNo
                City
                ContactDetails {
                    Contact {
                        ContactNo
                        LinkedProducts
                    }
                }
                DateOfBirth
                EmailAddress
                Gender
                IDCountryCode
                IDExpiryDate
                IDNo
                IDType
                LBInfo {
                    Contract {
                        AccountNo
                        AllowedInStore
                        Balance
                        CollectionNo
                        Date
                        Deposit
                        ExpiryDate
                        LastAmount
                        LastDate
                        LastStoreNo
                        LastTranDate
                        No
                        Status
                        StoreNo
                        Total
                        TotalPaid
                    }
                }
                Name
                NationalityCountryCode
                PostalCode
                ResidentCountryCode
                Suburb
                Surname
                Title
            }
            ResponseCode
        }
    }
`;
