<sister-site-bar class="hide-in-checkout" *ngIf="sisterSiteBarEnabled"></sister-site-bar>
<div class="menu-top hide-in-checkout">
    <div class="left">
        <a role="button" class="home-button" [fakeLink]="urlService.buildUrlForHome()" (fakeClick)="handleLink($event)" [attr.title]="'menu.home' | translate"></a>
    </div>
    <div class="middle" *ngIf="isBrowser">
        <search-bar-generic [isSearchResultsPage]="isSearchResultsPage" [dropDownReference]="searchResults2" [placeholder]="'search.placeholder' | translate" [dataSource]="productSearchDataSource">
            <product-search-bar-renderer #searchResults2></product-search-bar-renderer>
        </search-bar-generic>
    </div>
    <div class="right" *ngIf="isBrowser">
        <a #cartButton *ngIf="implementationDataService.getEcomEnabled()" class="btn btn-light cart-button" [fakeLink]="urlService.buildUrlForCheckoutCart()" (fakeClick)="handleLink($event)" (mouseenter)="openQuickLook()" (mouseleave)="closeQuickLook()">
            <facade-icon slot="icon-only" icon="icon-cart"></facade-icon> {{ 'menu.myCart' | translate }}
            <span class="cart-total" *ngIf="cartTotal > 0"><span class="number">{{ cartTotal }}</span></span>
        </a>
        <menu-button *ngIf="(loggedIn$ | async)" class="ack-admin-menu" (select)="handleLink($event)" [menuData]="{item:authMenuMainItem, categoryUrlKeyHash:categoryUrlKeyHash}" customIcon="icon-user">
            <div class="menu-holder">
                @if (implementationDataService.getPlusMoreEnabled()) {
                    <a [fakeLink]="this.urlService.buildUrlForAccountPlusMoreRewards()" (fakeClick)="handleLink($event)">{{ 'account.menu.plusMore' | translate }}</a>
                }
                <a [fakeLink]="this.urlService.buildUrlForAccountDetails()" (fakeClick)="handleLink($event)">{{ 'account.menu.accountDetails' | translate }}</a>
                <a [fakeLink]="this.urlService.buildUrlForAccountOrders()" (fakeClick)="handleLink($event)">{{ 'account.menu.orders' | translate }}</a>
                <hr />
                @if (storeCreditEnabled) {
                    <a [fakeLink]="this.urlService.buildUrlForOnlineCreditHistory()" (fakeClick)="handleLink($event)">{{ 'account.menu.onlineStoreCredit' | translate }}</a>
                }
                @if (implementationDataService.getEinsteinLaybyEnabled()) {
                    <a [fakeLink]="AckBuiltInPageMapping.account.laybys.urlKey" (fakeClick)="handleLink($event)">{{ 'menu.account.laybys' | translate }}</a>
                }
                @if (implementationDataService.getEinsteinAccountEnabled()) {
                    <a [fakeLink]="AckBuiltInPageMapping.account.ackAccount.urlKey" (fakeClick)="handleLink($event)">{{ 'menu.account.ackAccount' | translate }}</a>
                }
                <a [fakeLink]="AckBuiltInPageMapping.account.moreServices.urlKey" (fakeClick)="handleLink($event)">{{ 'menu.account.moreServices' | translate }}</a>
                <hr />
                <a [fakeLink]="this.urlService.buildUrlForHome()" (fakeClick)="handleLogout($event)">{{ 'account.menu.logout' | translate }}</a>
            </div>
        </menu-button>
        <a class="btn btn-secondary login-register-btn" *ngIf="!(loggedIn$ | async)" [fakeLink]="urlService.buildUrlForAuthLogin()" (fakeClick)="handleLink($event)">{{ 'menu.loginRegister' | translate }}</a>
    </div>
</div>

<div class="middle menu-main hide-in-checkout">
    @if (isBrowser) {
        @for (item of menuItems; track item.index) {
            @if (item.dropDown) {
                <menu-button [class]="item.cssClasses" [menuData]="{item:item, categoryUrlKeyHash:categoryUrlKeyHash}" [dropDownElement]="menu" (select)="handleLink($event)"></menu-button>
                <column-menu [menuData]="{dropDown:item.dropDown, sizes:sizes, categoryUrlKeyHash:categoryUrlKeyHash, parentItem:item}" #menu></column-menu>
            } @else {
                <menu-button [class]="item.cssClasses" [menuData]="{item:item, categoryUrlKeyHash:categoryUrlKeyHash}" (select)="handleLink($event)"></menu-button>
            }
        }
    }
</div>

<hr class="bottom-border hide-in-checkout" />
<div class="hide-in-checkout" #quicklookCartHolder></div>

<div class="checkout-header hide-out-checkout">
    <div class="left">
        <a class="back-btn btn btn-light btn-md" [fakeLink]="urlService.buildUrlForCheckoutCart()" (fakeClick)="handleLink($event)"><facade-icon icon="icon-chevron-left"></facade-icon> {{ 'header.back' | translate }}</a>
    </div>
    <div class="center">
        <a role="button" class="home-button" [fakeLink]="urlService.buildUrlForHome()" (fakeClick)="handleLink($event)" [attr.title]="'menu.home' | translate"></a>
    </div>
    <div class="right">
        <span class="support"><facade-icon icon="icon-help"></facade-icon> <span [innerHTML]="'header.support' | translate"></span></span>
    </div>
</div>
