<footer>
    <newsletter-sign-up></newsletter-sign-up>

    <div class="content-container">
        <div class="row links-row padding">
            <div class="col" *ngFor="let menuElementList of footerMenuItems">

                <ng-template ngFor let-menuElement [ngForOf]="menuElementList">

                    <a class="list-header" [fakeLink]="urlService.buildMenuElementUrl(menuElement)" (fakeClick)="handleLink($event)" *ngIf="menuElement.selectable !== false"><strong>{{footerMenuService.getButtonName(menuElement) | async}}</strong></a>
                    <span class="list-header" *ngIf="menuElement.selectable === false">{{footerMenuService.getButtonName(menuElement) | async}}</span>

                    <ul class="list-unstyled" *ngIf="menuElement.children && menuElement.children.length > 0">
                        <li *ngFor="let menuElement of menuElement.children">
                            <a [fakeLink]="urlService.buildMenuElementUrl(menuElement)" (fakeClick)="handleLink($event)">{{footerMenuService.getButtonName(menuElement) | async}}</a>
                        </li>
                    </ul>

                </ng-template>

            </div>

            <ng-container *ngIf="data$ | async as data">
                <div class="col app-column" *ngIf="data.iosAppUrl || data.androidAppUrl">
                    <h5 class="download-title">{{'app.downloadTitle' | translate}}</h5>
                    <a class="android-app" *ngIf="data.androidAppUrl" [fakeLink]="data.androidAppUrl" (fakeClick)="handleLink($event)" target="_blank"><img [facadeImg]="urlService.buildUrlForAsset('/assets/images/general/android-app-store.bce70b75a3bbe5db2372a3517a83f0b0.gif')" [alt]="'app.downloadAndroid' | translate"></a>
                    <a class="ios-app" *ngIf="data.iosAppUrl" [fakeLink]="data.iosAppUrl" (fakeClick)="handleLink($event)" target="_blank"><img [facadeImg]="urlService.buildUrlForAsset('/assets/images/general/ios-app-store.8f21845dde0b3c47a357a76eef10d526.gif')" [alt]="'app.downloadIOS' | translate"></a>
                </div>
            </ng-container>
        </div>

        <div class="text-center social-links">
            <ng-container *ngIf="data$ | async as data">
                <a *ngIf="data.facebookLink" [fakeLink]="data.facebookLink" (fakeClick)="handleLink($event)" target="_blank" class="grey-bubble"><facade-icon icon="icon-facebook"></facade-icon></a>
                <a *ngIf="data.twitterLink" [fakeLink]="data.twitterLink" (fakeClick)="handleLink($event)" target="_blank" class="grey-bubble"><facade-icon icon="icon-twitter"></facade-icon></a>
                <a *ngIf="data.youTubeLink" [fakeLink]="data.youTubeLink" (fakeClick)="handleLink($event)" target="_blank" class="grey-bubble"><facade-icon icon="icon-youtube"></facade-icon></a>
                <a *ngIf="data.instagramLink" [fakeLink]="data.instagramLink" (fakeClick)="handleLink($event)" target="_blank" class="grey-bubble"><facade-icon icon="icon-instagram"></facade-icon></a>
                <a *ngIf="data.linkedInLink" [fakeLink]="data.linkedInLink" (fakeClick)="handleLink($event)" target="_blank" class="grey-bubble"><facade-icon icon="icon-linkedin"></facade-icon></a>
                <a *ngIf="data.whatsappLink" [fakeLink]="data.whatsappLink" (fakeClick)="handleLink($event)" target="_blank" class="grey-bubble"><facade-icon icon="icon-whatsapp"></facade-icon></a>
            </ng-container>
            <div class="final-row match-bg" [innerHTML]="'footer.copyright' | translate:yearData"></div>
        </div>
    </div>
</footer>
