<footer>
    <div class="core-content footer-inner hide-in-checkout">
        <div class="d-flex flex-row">
            <ack-newsletter-sign-up></ack-newsletter-sign-up>
        </div>
        <div class="site-logo-row">
            <img class="logo" [src]="urlService.buildUrlForAsset('assets/images/general/ack-logo.19c106673889f63646fa4018a9530cbc.svg')" />
        </div>
        <div class="row links-row">
            <div class="col" *ngFor="let menuElementList of footerMenuItems">
                <ng-template ngFor let-menuElement [ngForOf]="menuElementList">
                    <a class="list-header" [fakeLink]="urlService.buildMenuElementUrl(menuElement)" (fakeClick)="handleLink($event)" *ngIf="menuElement.selectable !== false">{{ footerMenuService.getButtonName(menuElement) | async }}</a>
                    <span class="list-header" *ngIf="menuElement.selectable === false">{{ footerMenuService.getButtonName(menuElement) | async }}</span>
                    <ul class="list-unstyled" *ngIf="menuElement.children && menuElement.children.length > 0">
                        <li *ngFor="let menuElement of menuElement.children">
                            <a [fakeLink]="urlService.buildMenuElementUrl(menuElement)" (fakeClick)="handleLink($event)">{{ footerMenuService.getButtonName(menuElement) | async }}</a>
                        </li>
                    </ul>
                </ng-template>
            </div>
        </div>
        <div class="payment-social-row">
            <div class="payment-row">
                <div class="payment-accept-label">
                    {{ 'footer.paymentAccept' | translate }}
                </div>
                <div class="payment-cols">
                    <div class="payment-col" *ngFor="let paymentType of paymentLogos">
                        <div class="payment-logo" *ngFor="let paymentLogo of paymentType.items">
                            <img class="payment-logo__image" [alt]="paymentLogo.label" [src]="urlService.buildUrlForAsset(paymentLogo.imageUrl)" />
                            <div class="payment-logo__label">{{ paymentLogo.label }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="social-icons" *ngIf="data$ | async as data">
                <a *ngIf="data.facebookLink" [fakeLink]="data.facebookLink" (fakeClick)="handleLink($event)" target="_blank" class="social-icon">
                    <facade-icon icon="icon-facebook"></facade-icon>
                </a>
                <a *ngIf="data.twitterLink" [fakeLink]="data.twitterLink" (fakeClick)="handleLink($event)" target="_blank" class="social-icon">
                    <facade-icon icon="icon-twitter"></facade-icon>
                </a>
                <a *ngIf="data.youTubeLink" [fakeLink]="data.youTubeLink" (fakeClick)="handleLink($event)" target="_blank" class="social-icon">
                    <facade-icon icon="icon-youtube"></facade-icon>
                </a>
                <a *ngIf="data.instagramLink" [fakeLink]="data.instagramLink" (fakeClick)="handleLink($event)" target="_blank" class="social-icon">
                    <facade-icon icon="icon-instagram"></facade-icon>
                </a>
                <a *ngIf="data.linkedInLink" [fakeLink]="data.linkedInLink" (fakeClick)="handleLink($event)" target="_blank" class="social-icon">
                    <facade-icon icon="icon-linkedin-alt"></facade-icon>
                </a>
                <a [fakeLink]="urlService.buildUrlForStoreFinder()" (fakeClick)="handleLink($event)" class="social-icon">
                    <facade-icon icon="icon-map-pin-circle"></facade-icon>
                </a>
            </div>
        </div>
    </div>
    <div class="legal-row hide-in-checkout">
        <div class="legal-col" *ngFor="let footerCol of footerTermsItems">
            <div class="legal-item-link" *ngFor="let menuElement of footerCol.children">
                <a [fakeLink]="urlService.buildMenuElementUrl(menuElement)" (fakeClick)="handleLink($event)">{{ footerMenuService.getButtonName(menuElement) | async }}</a>
            </div>
            <div class="legal-item-link copyright">
                <span [innerHTML]="'footer.copyright' | translate:yearData"></span>
            </div>
        </div>
    </div>

    <div class="checkout-footer hide-out-checkout">
        <p class="line1">Need Help?</p>
        <p class="line2">
            Check out our <a [fakeLink]="AckCMSPageLinks.returnsFAQ" (fakeClick)="handleLink($event)">FAQ</a> or feel free to <a [fakeLink]="urlService.buildUrlForContact()" (fakeClick)="handleLink($event)">Contact Us</a>
        </p>
        <div class="links">
            <a [fakeLink]="AckCMSPageLinks.termsGeneral" (fakeClick)="handleLink($event)">Terms & Conditions</a>
            <a [fakeLink]="AckCMSPageLinks.termsPrivacy" (fakeClick)="handleLink($event)">Pepkor Privacy Policy</a>
            <span [innerHTML]="'footer.copyright' | translate:yearData"></span>
        </div>
    </div>
</footer>
