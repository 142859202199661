import {Routes} from '@angular/router';
import {CustomPageEnum} from '../../../cross-platform/custom-page.enum';
import {PageTypeEnum} from '../../../../../../framework/src/cross-platform/router/page-type.enum';

export const implRoutes:Routes = [
    {path: PageTypeEnum.ACCOUNT, loadChildren: () => import(`../account/account.module`).then(m => m.AccountModule)},
    {path: PageTypeEnum.CONTACT_US, loadChildren: () => import(`../contact-us-page/contactus-page.module`).then(m => m.ContactusPageModule)},
    {path: PageTypeEnum.CUSTOM + '/' + CustomPageEnum.braGuide, loadChildren: () => import(`../custom-pages/bra-guide/bra-guide-custom-page.module`).then(m => m.BraGuideCustomPageModule)},
    {path: PageTypeEnum.CUSTOM + '/' + CustomPageEnum.more, loadChildren: () => import(`../custom-pages/more/more-custom-page.module`).then(m => m.MoreCustomPageModule)},
];

