import {ModuleWithProviders, NgModule} from '@angular/core';
import {StaticCardImageComponent} from './custom-pages/static-card-image/static-card-image.component';
import {CommonModule} from '@angular/common';
import {CrossPlatformModule} from '../../../../framework/src/cross-platform/cross-platform.module';

@NgModule({
    imports     : [
        CommonModule,
        CrossPlatformModule
    ],
    declarations: [
        StaticCardImageComponent,
    ],
    exports     : [
        StaticCardImageComponent,
    ],
    providers   : [/*Do not add providers here as multiple will be created, add in forRoot*/]
})
export class CrossPlatformSharedModule {
    static forRoot():ModuleWithProviders<CrossPlatformSharedModule> {
        return {
            ngModule : CrossPlatformSharedModule,
            providers: []
        };
    }
}
