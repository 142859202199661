import {Injectable, Injector} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {
    CustomerLogicService
} from '../../../../../../framework/src/cross-platform/external-data/customer/customer-logic.service';
import {ExternalResponse} from '../../../../../../framework/src/cross-platform/external-data/external-response';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import {debug} from '../../../../../../framework/src/cross-platform/shared/rxjs-operators';
import {
    FETCH_CUSTOMER_STATEMENT_EINSTEIN,
    FETCH_CUSTOMER_STATEMENT_EINSTEIN_CACHE
} from './gql-queries/fetch-customer-statement';
import {
    APlusTransactionDetailed,
    FetchCustomerInfoResponse,
    FetchCustomerStatementResponse,
    Response
} from '../../../../../../generated/graphql';
import {LINK_CUSTOMER_ID_NUMBER} from './gql-mutations/link-customer-id-number';
import dayjs from 'dayjs';
import {RecaptchaAction} from '../../../../../../framework/src/cross-platform/external-data/recaptcha/recaptcha-action';
import {RootState} from '../../../../../../framework/src/cross-platform/ngxs/root.state';
import {
    FETCH_CUSTOMER_INFO_EINSTEIN,
    FETCH_CUSTOMER_INFO_EINSTEIN_CACHE
} from '../../../../../../framework/src/cross-platform/external-data/customer/gql-queries/fetch-customer-info-einstein';
import {
    LINK_CUSTOMER_ID_NUMBER_EINSTEIN
} from '../../../../../../framework/src/cross-platform/external-data/customer/gql-queries/link-customer-id-einstein';


export interface LinkCustomerIdNumberResponseWrapper {
    linkCustomerIdNumber:Response;
}

export interface FetchCustomerInfoResponseWrapper {
    fetchCustomerInfo:FetchCustomerInfoResponse;
}

export interface FetchCustomerStatementRes {
    fetchCustomerStatement:FetchCustomerStatementResponse;
}


@Injectable()
export class AckCustomerLogicService extends CustomerLogicService {

    constructor(injector:Injector) {
        super(injector);
    }

    linkCustomerIdNumberEinstein(customerIdNumber:string) {
        this.recaptchaService.execute(RecaptchaAction.AUTH_LINK_ID);
        return this.apollo.mutate<LinkCustomerIdNumberResponseWrapper>({mutation: LINK_CUSTOMER_ID_NUMBER_EINSTEIN, variables: {customerIdNumber: customerIdNumber}}, true);
    }

    getCustomerInfoEinstein() {
        return this.apollo.query<FetchCustomerInfoResponseWrapper>({query: FETCH_CUSTOMER_INFO_EINSTEIN}, FETCH_CUSTOMER_INFO_EINSTEIN_CACHE, true).pipe(map(
            res => {
                if (res?.data?.fetchCustomerInfo?.CustomerDetails) {
                    let original = res.data.fetchCustomerInfo.CustomerDetails;

                    // Iterate all of the dates an parse manually so the UI knows how to deal with them
                    if (original.IDExpiryDate) original.IDExpiryDate = this.fixDate(original.IDExpiryDate);

                    if (original?.AccountList?.Account instanceof Array) {
                        // Only show accounts where the card status has been set to Active. See: https://plan.pepkorit.com/browse/CDFAC-876
                        // Also https://plan.pepkorit.com/browse/CDCQ-245
                        // This was changed when einstein refactored, the original code to resolve card status could not be located
                        original.AccountList.Account = original.AccountList.Account.filter(account => account?.AccountStatus?.toLowerCase() === 'active');

                        original.AccountList.Account.forEach(account => {
                            if (account.AccountBalance?.PaymentDueDate) {
                                account.AccountBalance.PaymentDueDate = this.fixDate(account.AccountBalance.PaymentDueDate);
                            }
                            if (account.Statements?.Statement instanceof Array) {
                                account.Statements.Statement.forEach(statement => {
                                    if (statement.CloseBalanceDate) statement.CloseBalanceDate = this.fixDate(statement.CloseBalanceDate);
                                    if (statement.OpenBalanceDate) statement.OpenBalanceDate = this.fixDate(statement.OpenBalanceDate);
                                    if (statement.PaymentDueDate) statement.PaymentDueDate = this.fixDate(statement.PaymentDueDate);
                                    if (statement.StatementDate) statement.StatementDate = this.fixDate(statement.StatementDate);
                                });
                                account.Statements.Statement.sort((a, b) => {
                                    return dayjs(b.StatementDate).toDate().getTime() - dayjs(a.StatementDate).toDate().getTime();
                                });
                            }
                        });
                    }

                    if (original.LBInfo?.Contract instanceof Array) {
                        original.LBInfo.Contract.forEach(layby => {
                            if (layby.Date) layby.Date = this.fixDate(layby.Date);
                            if (layby.ExpiryDate) layby.ExpiryDate = this.fixDate(layby.ExpiryDate);
                            if (layby.LastDate) layby.LastDate = this.fixDate(layby.LastDate);
                            if (layby.LastTranDate) layby.LastTranDate = this.fixDate(layby.LastTranDate);
                        });

                        original.LBInfo.Contract.sort((a, b) => {
                            if (b.Date && a.Date) {
                                return dayjs(b.Date).toDate().getTime() - dayjs(a.Date).toDate().getTime();
                            }
                            return 0;
                        });
                    }
                    this.logger.debug('After dates: ', original);
                }

                return res;
            }
        ));
    }

    /*mapResponseToExtendedGenericCustomer(guid:string, res:ExternalResponse<FetchCustomerInfoResponseWrapper>):GenericCustomer {
        const customer = this.mapResponseToGenericCustomer(res) as GenericCustomer<AckCustomerAdditionalInfo>;
        let original   = res.data.fetchCustomerInfo.CustomerDetails;


        // Sanitise the response from profile
        // ----------------------------------

        // If we got a partial profile it means the cellphone number will be populated into the ID number
        // This is due to a design decision in one profile to store it this way, not sure why.
        // We now have to determine a partial response from the server using the 206 and null
        // out the values so the data makes some sense without the status code in tow
        /!*if (res.httpResponse && res.httpResponse.status === 206) {
            original.IDNo          = null;
            original.IDType        = null;
            original.IDCountryCode = null;
        }*!/
        if (original.IDNo === original.CellphoneNo) {
            original.IDNo          = null;
            original.IDType        = null;
            original.IDCountryCode = null;
        }

        // Next we want to clean out the object of all the null and empty strings
        //this.logger.debug('Before clean: ', original);
        original = this.sanitize.cleanObject(original);
        //this.logger.debug('After clean: ', original);

        this.logger.debug('Before dates: ', original);

        // Apply all the profile specific values
        // -------------------------------------
        //customer.username        = original.CellphoneNo;
        customer.cellPhoneNumber = original.CellphoneNo;
        //customer.idNumber        = original.IDNo;
        //customer.idCountryCode   = original.IDCountryCode;
        //customer.idExpiryDate    = original.IDExpiryDate;
        //customer.idType          = original.IDType;
        customer.additionalData = {
            AccountList                : original.AccountList,
            AplusApplicationInformation: original.AplusApplicationInformation,
            LBInfo                     : original.LBInfo,
            NationalityCountryCode     : original.NationalityCountryCode
        };

        return customer;
    }*/


    // Deprecated, only used in unused section, account edit
    linkIdNumber<T>(idNumber:string):Observable<ExternalResponse<any>> {
        this.recaptchaService.execute(RecaptchaAction.AUTH_LINK_ID);
        this.logger.debug('ID number link called');
        const notNull = filter(v => v != null);

        return combineLatest(
            this.store.select((s:RootState) => s.customer.isLoggedIn).pipe(notNull, take(1), debug('isLoggedIn: ')),
            this.store.select((s:RootState) => s.customer.username).pipe(notNull, take(1), debug('username: ')),
        )
            .pipe(
                mergeMap((values) => {
                    return this.apollo.mutate<Response, any>(
                        {
                            mutation : LINK_CUSTOMER_ID_NUMBER,
                            variables: {
                                guid     : values[0] as string,
                                msisdn   : values[1] as string,
                                id_number: idNumber
                            },
                        },
                        true
                    );
                })
            );
    }

    fetchCustomerStatementEinstein(statementNumber:string) {
        return this.apollo.query<FetchCustomerStatementRes>({query: FETCH_CUSTOMER_STATEMENT_EINSTEIN, variables: {statementNumber: statementNumber}}, FETCH_CUSTOMER_STATEMENT_EINSTEIN_CACHE, true)
            .pipe(
                map(res => {
                    const newRes = Object.assign({}, res);
                    if (res && res.data && res.data.fetchCustomerStatement.Transactions && res.data.fetchCustomerStatement.Transactions instanceof Array) {
                        newRes.data.fetchCustomerStatement.Transactions = newRes.data.fetchCustomerStatement.Transactions.map(item => Object.assign({}, item));
                        newRes.data.fetchCustomerStatement.Transactions.forEach(trans => {
                            if (trans.TransactionDate) trans.TransactionDate = this.fixDate(trans.TransactionDate);
                            if (trans.TransactionEffectiveDate) trans.TransactionEffectiveDate = this.fixDate(trans.TransactionEffectiveDate);
                        });
                        newRes.data.fetchCustomerStatement.Transactions.sort((a:APlusTransactionDetailed, b:APlusTransactionDetailed) => {
                            return dayjs(b.TransactionDate).toDate().getTime() - dayjs(a.TransactionDate).toDate().getTime();
                        });
                    }
                    return newRes;
                })
            );
    }

    private fixDate(date:string):string {
        return dayjs(date).toISOString();
    }
}
