import {gql} from 'apollo-angular';

export const LINK_CUSTOMER_ID_NUMBER = gql`
    mutation linkCustomerIdNumber(
        $guid: String
        $msisdn: String
        $id_number: String
        $id_type: String
        $id_country: String
    ) {
        linkCustomerIdNumber(
            guid: $guid
            msisdn: $msisdn
            id_number: $id_number
            id_type: $id_type
            id_country: $id_country
        ) {
            ResponseCode
            ResponseDescription
        }
    }

`;
