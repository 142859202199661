import {gql} from 'apollo-angular';

export const FETCH_CUSTOMER_STATEMENT_EINSTEIN_CACHE = false;

export const FETCH_CUSTOMER_STATEMENT_EINSTEIN = gql`
    query fetchCustomerStatement($statementNumber: String) {
        fetchCustomerStatement(statementNumber: $statementNumber) {
            ResponseCode
            Transactions {
                CardNumber
                StoreName
                TransactionAmount
                TransactionDate
                TransactionDescription
                TransactionEffectiveDate
                TransactionTypeIndicator
            }
        }
    }
`;
