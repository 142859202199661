import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AppFooterComponent} from '../../../../../../framework/src/desktop/app/footer/app-footer.component';
import {
    PaymentLogoType
} from '../../../../../../framework/src/cross-platform/implementation-config/implementation-data.types';
import {AckCMSPageLinks} from '../../../cross-platform/ack-custom-page-mapping';

@Component({
    selector       : 'app-footer',
    templateUrl    : './ack-app-footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AckAppFooterComponent extends AppFooterComponent {

    paymentLogos:PaymentLogoType[];
    protected readonly AckCMSPageLinks = AckCMSPageLinks;

    constructor(injector:Injector) {
        super(injector);
        this.paymentLogos = this.implementationDataService.getPaymentLogos();
    }

    get name() {
        return 'AckAppFooterComponent';
    }
}
