import {ModuleWithProviders, NgModule} from '@angular/core';
import {ImplementationDataService} from '../../../../framework/src/cross-platform/implementation-config/implementation-data.service';
import {AckImplementationDataService} from './implementation-config/ack-implementation-data.service';
import {ACCORDION_CONFIG_TOKEN} from '../../../../framework/src/cross-platform/accordion/facade-accordion.component';
import {CustomerLogicService} from '../../../../framework/src/cross-platform/external-data/customer/customer-logic.service';
import {AckCustomerLogicService} from './external-data/customer/ack-customer-logic-service';

@NgModule({})
export class CrossPlatformImplementationAppModule {
    static forRoot():ModuleWithProviders<CrossPlatformImplementationAppModule> {
        return {
            ngModule : CrossPlatformImplementationAppModule,
            providers: [
                {provide: ImplementationDataService, useClass: AckImplementationDataService},
                {provide: ACCORDION_CONFIG_TOKEN, useValue: {scrollingOffset: 0}},
                {provide: AckCustomerLogicService, useClass: AckCustomerLogicService},
                {provide: CustomerLogicService, useClass: AckCustomerLogicService}
            ]
        };
    }
}
