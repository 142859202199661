import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NewsletterSignUpModalComponent} from '../../../../../../framework/src/desktop/app/footer/newsletter-sign-up-modal.component';
import {AbstractNewsletterSignUpComponent} from '../../../../../../framework/src/cross-platform/newsletter/abstract-newsletter-sign-up.component';

@Component({
    selector       : 'ack-newsletter-sign-up',
    templateUrl    : 'ack-newsletter-sign-up.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AckNewsletterSignUpComponent extends AbstractNewsletterSignUpComponent {
    private ngbModal:NgbModal;
    private modalRef:NgbModalRef;

    constructor(injector:Injector) {
        super(injector);
        this.ngbModal = injector.get(NgbModal);
    }

    launchModal(email?:string) {
        this.modalRef = this.ngbModal.open(NewsletterSignUpModalComponent, {windowClass: 'newsletter-signup-popup'});
        const comp    = this.modalRef.componentInstance as NewsletterSignUpModalComponent;
        comp.populate(email);
        this.modalRef.result
            .then(
                result => {
                    this.handleModalClose(result);
                },
                other => {
                    this.handleModalClose(false);
                }
            );
    }

    dismissModal() {
        // dismiss here
        if (this.modalRef) {
            this.modalRef.close(false);
        }
    }
}
