import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AbstractFacadeComponent} from '../abstract-facade.component';
import {SisterSiteSettings} from '../../../implementation-config/implementation-data.types';

@Component({
    selector       : 'sister-site-bar',
    template       : `
<!--        <div class="sister-site-bar">-->
<!--            <ul>-->
<!--                <li *ngFor="let site of sisterSiteBarSettings" [ngClass]="site.active ? 'active' : ''">-->
<!--                    <a [href]="site.link" *ngIf="!site.active" target="_blank"><img [src]="urlService.buildUrlForAsset(site.imageUrl)" [alt]="site.alt" /></a>-->
<!--                    <span *ngIf="site.active"><img [src]="urlService.buildUrlForAsset(site.imageUrl)" [alt]="site.alt" /></span>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SisterSiteBar extends AbstractFacadeComponent {
    sisterSiteBarSettings: SisterSiteSettings[];
    constructor(injector:Injector) {
        super(injector);
        this.sisterSiteBarSettings = this.implementationDataService.getSisterSiteSettings();
    }

    get name() {
        return 'SisterSiteBar';
    }
}
