import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {NavigationEnd} from '@angular/router';
import {AppComponent} from '../../../../../framework/src/desktop/app/app.component';

@Component({
    selector       : 'app-root',
    templateUrl    : '../../../../../framework/src/desktop/app/app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AckAppComponent extends AppComponent {

    private prevUrl: string;
    constructor(public injector:Injector) {
        super(injector);
    }

    // This was disabled on request: https://www.notion.so/pepkorit/Desktop-pagination-doesn-t-scroll-to-top-dc08e266daa84c48a9dda52c7df2cb5d
    // onPageNavEnd(event:NavigationEnd) {
    //     super.onPageNavEnd(event, true);
    //
    //     let newUrl = event.url;
    //     if (newUrl.indexOf('?') !== -1) newUrl = newUrl.substr(0, newUrl.indexOf('?'));
    //
    //     // Ensure that URL change scrolls to top except when on same product list page (param change)
    //     // if (!(this.urlService.isProductListUrl(newUrl) && this.prevUrl === newUrl)) {
    //     //     this.logger.info('Router is not on product list page with param update', event);
    //     //     this.facadePlatform.scrollToTop();
    //     // }
    //
    //     this.prevUrl = newUrl;
    // }
}
